body {
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
}

html {
  overflow-x: hidden;
}

p {
  font-size: 14px;
  line-height: 26px;
}

.test {
  border: 2px solid red;
  text-align: right;
  font-size: 16px;
}

a:hover,
a:focus {
  color: #01a8be;
}

@media (max-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    margin-left: 22px;
    margin-right: 22px;
  }
  .navbar-expand-md .navbar-nav .nav-link:last-child {
    margin-bottom: 20px !important;
  }
  .navbar-collapse {
    background-color: rgba(13, 37, 45, 0.95) !important;
  }
}

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;
}

ul li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

#hero-area {
  // background: url(./assets/img/cover-sm.png) fixed no-repeat;
  // background-size: contain;
  // background-position: right top;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.filler {
  flex-grow: 1;
  // border: 2px dotted gray;
}

#staffing .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#staffing .icon {
  border: 1px solid #01a8be;
  text-align: center;
  float: left;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#staffing .icon i {
  color: #01a8be;
  font-size: 24px;
  line-height: 60px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#staffing i {
  color: #01a8be;
  font-size: 24px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#staffing li:hover .icon {
  background: #01a8be;
}

#staffing li:hover .icon i {
  color: #ffffff;
}

#staffing .skill-text {
  color: #01a8be;
  font-weight: 500;
  font-size: 16px;
  // line-height: 22px;
  margin-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.skills-container {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

.skills {
  margin-bottom: 20px;
}

.skill {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.provider-image-col {
  margin-top: 20px;
}

.provider-image {
  width: 100%;
}

.skills-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skills-section .register,
.skills-section .list {
  margin: 20px;
}

.crna-plx {
  width: 92%;
  margin: 0 auto;
}

#crna-area {
  height: 200px;
}

@media (min-width: 768px) {
  .skills-section .register {
    max-width: 380px;
  }

  // #hero-area {
  //   background: url(./assets/img/cover-lg.png) fixed no-repeat;
  //   background-size: contain;
  // }

  #crna-area {
    height: 300px;
  }

  .crna-plx {
    width: 65%;
  }
}

// .jobs ul {
//   text-align: left;
// }

//   .indigo {
//   background: transparent;
// }

// .navbar-brand {
//   position: relative;
//   padding: 0px;
// }

// a:not([href]):not([tabindex]) {
//   color: #fff;
// }

.navbar {
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-expand-md .navbar-nav .nav-link {
  color: #fff;
  padding: 0 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 40px;
  border-radius: 30px;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar .navbar-nav .nav-link:hover,
// .navbar .navbar-nav .active > a,
.navbar .navbar-nav .nav-link:focus {
  color: #01a8be;
}

.navbar .nav-link.active {
  color: #fff !important;
  background: #01a8be;
}

.nav-item {
  cursor: pointer;
}

#hero-area .contents {
  padding: 140px 0 120px;
}

.img-fluid {
  max-height: 24px;
}

.navbar-brand {
  padding-bottom: 0.8125rem;
}

.navbar-scroll-color {
  background-color: #1f3036;
}

.expander {
  flex-grow: 1;
}
// .mobile-menu {
//   display: none;
// }

.test-section {
  width: 100%;
  height: 200px;
}

.mr-auto {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.section {
  padding: 40px 0 20px;
}

.section-header {
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}

.section-header .section-title {
  font-size: 36px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  position: relative;
}

.section-header .lines {
  margin: auto;
  width: 70px;
  position: relative;
  border-top: 2px solid #01a8be;
  margin-top: 15px;
}

.section-header .section-subtitle {
  margin: 15px auto 0;
  max-width: 85%;
  margin-top: 15px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

#about-us .about-us-item-wrapper {
  background: #fff;
  border-radius: 0px;
  -webkit-transition: transform 0.2s linear, -webkit-box-shadow 0.2s linear;
  -moz-transition: transform 0.2s linear, -moz-box-shadow 0.2s linear;
  transition: transform 0.2s linear, box-shadow 0.2s linear;
}

#about-us .about-us-item-img {
  position: relative;
}

#about-us .about-us-item-img img {
  width: 100%;
}

.provider-sign-up {
  // background-color: #1f3036f2;
  vertical-align: middle;
  // margin: 16px 0;
  // color: white;
}

.provider-sign-up .label {
  text-align: right;
  margin-bottom: 12px;
  font-size: 18px;
}

.provider-sign-up .button {
  text-align: left;
}

#contact {
  background: #343a40;
  color: #fff;
  position: relative;
}

.contact-us h3 {
  font-size: 36px;
  margin-bottom: 45px;
}

.contact-us p span {
  color: #01a8be;
  padding-left: 10px;
}

footer {
  background: #292f35;
  padding: 12px 0 0 0;
}

.copyright {
  margin-top: 6px;
  text-align: center;
}

.copyright p {
  color: #fff;
  font-size: 10px;
  line-height: 14px;
}

.text-center {
  text-align: center !important;
}

.btn {
  font-size: 14px;
  padding: 11px 40px;
  border-radius: 0px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: inline-block;
}
.btn-common {
  border: 1px solid #01a8be;
  background: #01a8be;
  position: relative;
  color: #fff;
  z-index: 1;
  border-radius: 30px;
}

.btn-common-sq {
  border: 1px solid #01a8be;
  background: #01a8be;
  position: relative;
  color: #fff;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  //   .navbar-header {
  //     width: 100%;
  //   }
  //   // .navbar-brand {
  //   //   position: absolute;
  //   //   padding: 15px;
  //   //   top: 0;
  //   // }
  //   .navbar-brand img {
  //     max-width: 85%;
  //   }
  //   .mobile-menu {
  //     display: block;
  //   }
  h2 {
    font-size: 20px;
  }

  #hero-area .contents {
    padding: 100px 0 60px;
  }

  .hero-plx {
    max-height: 300px;
  }

  .section-header .section-title {
    font-size: 20px;
  }
}
